@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@layer base {
  :root {
    --shiki-color-text: theme('colors.white');
    --shiki-token-constant: theme('colors.green.400');
    --shiki-token-string: theme('colors.green.400');
    --shiki-token-comment: theme('colors.zinc.500');
    --shiki-token-keyword: theme('colors.sky.300');
    --shiki-token-parameter: theme('colors.pink.300');
    --shiki-token-function: theme('colors.violet.300');
    --shiki-token-string-expression: theme('colors.green.400');
    --shiki-token-punctuation: theme('colors.zinc.200');
  }
}

body{
  background-color: #1e2336;
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background: #292d45;
  height: 40%;
  padding-top: 10px;
}

::-webkit-scrollbar-thumb:active {
  background: #3d4363;
}

.wave {
  animation-name: wave-animation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

#navbar, #footer{
  background-color: rgb(30 35 54);
  z-index:1000;
}

.navbar-top{
  position: sticky;
  top: 0;
}

#animated-text{
  color:aqua;
  font-weight: 300;
}

.scroll-down {
  position: relative;
  top: 50%;
  left: 50%;
  width:20px;
  margin-top: 4em;
}

.scroll-down span {
  display: block;
  width: 16px;
  height: 16px;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  transform: rotate(45deg);
  margin: -10px;
  animation: scroll-down 1.5s infinite;
}

.scroll-down span:nth-child(2) {
  animation-delay: -0.2s;
}

.scroll-down span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes scroll-down {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-15px, -15px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(15px, 15px);
  }
}

#stars-container canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

#trophy{
  color: gold;
}

.skills-picker {
  position: relative;
  border: 4px solid #282828;
  overflow: hidden;
  border-radius: 100%;
  box-shadow:
    -5px -5px 5px rgba(255, 255, 255, 0.1),
    10px 10px 10px rgba(0, 0, 0, 0.4),
    inset -5px -5px 5px rgba(255, 255, 255, 0.2),
    inset 10px 10px 10px rgba(0, 0, 0, 0.4);
}

.skills-buttons {
  background: #212121;
  padding: 2px;
  box-shadow:
    inset -2px -2px 5px rgba(0, 0, 0, 0.5),
    inset 3px 3px 5px rgba(151, 154, 212, 0.5);
}

.skills-buttons-active {
  padding: 0;
  border: 2px solid #292929;
  box-shadow:
    inset -2px -2px 5px rgba(151, 154, 212, 0.3),
    inset 3px 3px 5px rgba(0, 0, 0, 0.5);
}

.skills-picker:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-image: linear-gradient(-250deg, #8fd16e 0%, #75c565 50%, #31d04e 100%);
  filter: blur(10px);
  z-index: -1;
  animation: hue 3s linear infinite;
}

@keyframes hue {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}

.my-work-img-shadow {
  box-shadow: -4px -4px 25px 4px #000000;
}

.my-work-button::before {
  content: '';
  position: absolute;
  width: 0%;
  height: 3px;
  bottom: 0;
  transform: translateY(6px);
  transition: all 0.3s ease-in-out;
}

.my-work-button-container:hover .my-work-button::before {
  width: 100%;
}

.my-work-button-container:hover span {
  transform: translateX(4px);
}

.my-work-button-container:hover .my-work-button-arrow {
  transform: translateX(0);
}

.project-img{
  width: 280px;
}

.loader {
  width: 20px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side,#000 90%,#0000) 0/calc(100%/3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 1s steps(4) infinite;
}
@keyframes l1 {to{clip-path: inset(0 -34% 0 0)}}

.check {
  display: inline-block;
  transform: rotate(45deg);
  height: 20px;
  width: 12px;
  border-bottom: 7px solid #78b13f;
  border-right: 7px solid #78b13f;
}

.hover-image-container {
  position: relative;
  display: inline-block;
}


.hover-text {
  position: absolute;
  transform: translate(-50%, -50%);
  right:1%;
  background-color: #1b2133;
  padding: 15px;
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.6s;
  pointer-events: none; /* Ensures the text doesn't interfere with hover */
  z-index: 100000;
}

.hover-image-container:hover .hover-text {
  opacity: 1;
}
